import React from 'react';
import ReactMarkdown from 'react-markdown';
import { graphql } from 'gatsby';

import Layout from '~/components/layout';
import MemeList from '~/components/meme-list';
import SEO from '~/components/seo';
import Image from '~/components/image';

const MemePage = ({ data }) => {
  const meme = data.strapiMeme;

  const seo = { title: meme.title, image: meme.image.publicURL };

  const flexJustify = meme.specifications.length > 0 ? 'between' : 'center';

  return (
    <Layout>
      <SEO seo={seo} />
      <div className="mb-4">
        <h1 className="text-4xl text-center mb-1">{meme.title}</h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-24 mt-4">
        {meme.image && (
          <div className="md:col-span-4">
            <a href={meme.image.publicURL} target="_blank">
              <Image
                className="rounded-md"
                image={meme.image}
                alt={meme.title}
              />
            </a>
          </div>
        )}
      </div>
      <div className={`row flex flex-col justify-${flexJustify}`}>
        <div className="w-full">
          <div className="w-full flex text-sm justify-between items-between border-b mb-2 pb-1">
            {meme.tags &&
              meme.tags.map((tag, index, array) => (
                <span
                  key={`${tag}-${index}`}
                  className="font-extralight"
                >{`${tag}${index - 1 === array.length ? '' : ','}`}</span>
              ))}
          </div>
        </div>
      </div>
      <div className="my-6 mb-24">
        <h1 className="text-4xl font-bold text-center">Meme Description</h1>
        <hr className="mt-6 mb-12 m-auto w-24 border-t-4" />
        <ReactMarkdown
          className="prose md:w-4/5 m-auto"
          children={meme.description}
        />
      </div>
      {meme.relatedMemes.length > 0 && (
        <div className="flex flex-col my-6 mb-24">
          <h2 className="text-3xl font-bold text-center">Related Memes</h2>
          <hr className="mt-6 mb-12 m-auto w-24 border-t-4" />
          <MemeList
            memes={meme.relatedMemes}
            gridCols="grid-cols-1 md:grid-cols-2"
          />
        </div>
      )}
    </Layout>
  );
};

export const query = graphql`
  query MemeQuery($slug: String!) {
    strapiMeme(slug: { eq: $slug }) {
      title
      description
      id
      dealerUrl
      image {
        publicURL
        childImageSharp {
          gatsbyImageData(
            layout: FULL_WIDTH
            placeholder: BLURRED
            aspectRatio: 1.3
          )
        }
      }
      specifications {
        key
        value
      }
      relatedMemes {
        title
        id
        slug
        image {
          childImageSharp {
            gatsbyImageData(
              layout: FULL_WIDTH
              placeholder: BLURRED
              aspectRatio: 1.3
            )
          }
        }
      }
    }
  }
`;

export default MemePage;
